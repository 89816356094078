import { useEffect, useState } from 'react';

export const useIsReactNativeWebView = () => {
  const [isWebview, setIsWebview] = useState<boolean>(true);

  useEffect(() => {
    if (navigator?.userAgent.indexOf('reactNativeWebview') === -1) {
      setIsWebview(false);
    }
  }, []);

  return isWebview;
};
